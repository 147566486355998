<template>
  <v-container fluid>
    <v-row justify="center" v-if="this.dialog">
      <v-dialog eager v-model="dialog" width="900px">
        <v-card>
          <v-card-title>
            <h4>{{ this.$store.getters["Auth/get_selected_ppm_rev"].fileName }}</h4>
            <v-spacer></v-spacer>
            <!--             <v-btn v-if="profile" @click="downloadPPM(2)" class="primary">
              <v-icon>mdi-download</v-icon>
              Télécharger
            </v-btn> -->
          </v-card-title>
          <v-divider></v-divider>
          <div style="padding:5px;padding-left:25px">
            <b>Description :</b>
            {{ this.$store.getters["Auth/get_selected_ppm_rev"].description }}
          </div>
          <v-divider></v-divider>
          <VueDocPreview
            v-if="
              this.$store.getters['Auth/get_selected_ppm_rev'].fileType == 'doc' ||
                this.$store.getters['Auth/get_selected_ppm_rev'].fileType == 'docx' ||
                this.$store.getters['Auth/get_selected_ppm_rev'].fileType == 'xls' ||
                this.$store.getters['Auth/get_selected_ppm_rev'].fileType == 'xlsx' ||
                this.$store.getters['Auth/get_selected_ppm_rev'].fileType == 'XLS' ||
                this.$store.getters['Auth/get_selected_ppm_rev'].fileType == 'XLSX'
            "
            :value="this.$store.getters['Auth/get_selected_ppm_rev'].fileLink"
            type="office"
          />
          <v-card-text>
            <v-row>
              <vue-friendly-iframe
                v-if="this.$store.getters['Auth/get_selected_ppm_rev'].fileType == 'pdf'"
                :src="this.$store.getters['Auth/get_selected_ppm_rev'].fileLink"
                class="w-62em"
                frameborder="0"
                loading="lazy"
              ></vue-friendly-iframe>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <v-card class="pa-2">
          <v-card-title>
            <h4>Détail du plan de passation des marchés (PPM) publié</h4>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <b>Référence du PPM :</b>
            {{ this.$store.getters["Auth/get_selected_ppm"].initialPpm.title }}
            <v-spacer class="my-1"></v-spacer>
            <b>Titre du PPM :</b>
            {{ this.$store.getters["Auth/get_selected_ppm"].initialPpm.description }}
            <v-spacer class="my-1"></v-spacer>
            <b>Date/heure de la publication du PPM :</b>
            {{
              this.$store.getters["Auth/get_selected_ppm"].initialPpm.publishDate
                | moment("dddd, Do MMMM YYYY à HH:mm:ss A Z")
            }}
          </v-card-text>
        </v-card>
        <v-card
          class="pa-2 mt-4"
          v-if="this.$store.getters['Auth/get_selected_ppm'].fileList.length > 0"
        >
          <v-card-title>
            <v-list-item class="block text-truncate">
              <v-list-item-content>
                <v-list-item-title
                  v-if="!initial"
                  class="primary--text text-truncate font-weight-bold"
                >
                  Voir le fichier initial
                </v-list-item-title>
                <v-list-item-title
                  v-if="initial"
                  class="primary--text text-truncate font-weight-bold"
                >
                  Voir le fichier actuel
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon @click="ShowInitial()">
                  <v-icon color="grey lighten-1">mdi-eye</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-card-title>
        </v-card>
        <v-card class="pa-2 mt-4">
          <v-card-title>
            <h4>Nombre et liste des plans de passation des marchés révisés</h4>
            <v-spacer></v-spacer
            ><v-chip class="ma-2">{{
              this.$store.getters["Auth/get_selected_ppm"].fileList.length
            }}</v-chip>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-list-item
                class="block text-truncate"
                v-for="item in this.$store.getters['Auth/get_selected_ppm'].fileList"
                :key="item.id"
              >
                <v-list-item-content>
                  <v-list-item-title class="primary--text text-truncate font-weight-bold">
                    {{ "REV N॰" + item.revision_number }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-truncate">{{
                    item.publishDate | moment("dddd, Do MMMM YYYY à HH:mm:ss A Z")
                  }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn icon @click="ShowTheRevision(item.id)">
                    <v-icon color="grey lighten-1">mdi-eye</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" v-if="!initial">
        <v-card>
          <v-card-title>
            <h4>Aperçu de l'actuel</h4>
            <v-spacer></v-spacer>
            <v-btn v-if="profile == 2" @click="downloadPPM()" class="primary">
              <v-icon>mdi-download</v-icon>
              Télécharger
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height:62em !important;overflow:hidden">
            <VueDocPreview
              style="height:100%"
              v-if="
                this.$store.getters['Auth/get_selected_ppm'].current_file.fileType == 'doc' ||
                  this.$store.getters['Auth/get_selected_ppm'].current_file.fileType == 'docx' ||
                  this.$store.getters['Auth/get_selected_ppm'].current_file.fileType == 'xls' ||
                  this.$store.getters['Auth/get_selected_ppm'].current_file.fileType == 'xlsx' ||
                  this.$store.getters['Auth/get_selected_ppm'].current_file.fileType == 'XLS' ||
                  this.$store.getters['Auth/get_selected_ppm'].current_file.fileType == 'XLSX'
              "
              :value="this.$store.getters['Auth/get_selected_ppm'].current_file.fileLink"
              type="office"
            />

            <vue-friendly-iframe
              v-if="this.$store.getters['Auth/get_selected_ppm'].current_file.fileType == 'pdf'"
              :src="
                this.$store.getters['Auth/get_selected_ppm'].current_file.fileLink + '#toolbar=0'
              "
              frameborder="0"
              loading="lazy"
            ></vue-friendly-iframe>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" v-if="initial">
        <v-card>
          <v-card-title>
            <h4>Aperçu du plan de passation des marchés (PPM) antérieur</h4>
            <v-spacer></v-spacer>
            <h6>
              Date :
              {{
                this.$store.getters["Auth/get_selected_ppm"].initialPpm.publishDate
                  | moment("dddd, Do MMMM YYYY à HH:mm:ss A Z")
              }}
            </h6>
          </v-card-title>
          <v-card-text style="height:62em !important;overflow:hidden">
            <VueDocPreview
              style="height:100%"
              v-if="
                this.$store.getters['Auth/get_selected_ppm'].initialPpm.ppmFileInitial_type ==
                  'doc' ||
                  this.$store.getters['Auth/get_selected_ppm'].initialPpm.ppmFileInitial_type ==
                    'docx' ||
                  this.$store.getters['Auth/get_selected_ppm'].initialPpm.ppmFileInitial_type ==
                    'xls' ||
                  this.$store.getters['Auth/get_selected_ppm'].initialPpm.ppmFileInitial_type ==
                    'xlsx' ||
                  this.$store.getters['Auth/get_selected_ppm'].initialPpm.ppmFileInitial_type ==
                    'XLS' ||
                  this.$store.getters['Auth/get_selected_ppm'].initialPpm.ppmFileInitial_type ==
                    'XLSX'
              "
              :value="InitLink"
              type="office"
            />
            <vue-friendly-iframe
              v-if="
                this.$store.getters['Auth/get_selected_ppm'].initialPpm.ppmFileInitial_type == 'pdf'
              "
              :src="InitLink"
              frameborder="0"
              loading="lazy"
            ></vue-friendly-iframe>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VueDocPreview from "vue-doc-preview";

export default {
  components: {
    VueDocPreview
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    initial: false,
    profile: 0,
    dialog: false,
    ppmRev: {
      file: [],
      description: ""
    },
    ppm: [],
    items: [
      {
        text: "Tableau de bord",
        disabled: false,
        href: "/dashboard"
      },
      {
        text: "Plan de passations des marchés",
        disabled: false,
        href: { name: "welcomePpm" }
      },
      {
        text: "Détail du plan de passation des marchés (PPM) publié",
        disabled: true
      }
    ]
  }),
  created() {
    this.$store.dispatch("Auth/getPPM", this.id);
    let Virt = [];
    Virt = JSON.parse(localStorage.getItem("userData"));
    this.profile = Virt.profile.status;
  },
  methods: {
    ShowTheRevision(value) {
      this.$store.dispatch("Auth/getPPMRev", value);
      this.dialog = !this.dialog;
    },
    getInit() {
      this.InitLink = this.$store.getters["Auth/get_selected_ppm"].initialPpm.ppmFileInitial_link;
    },
    async ShowInitial() {
      await this.getInit();
      this.initial = !this.initial;
    },
    downloadPPM() {
      const data = new FormData();
      data.append("id", this.id);
      this.$store.dispatch("Auth/downloadPpm", data);
    }
  }
};
</script>
<style>
.w-62em {
  height: 62em !important;
  width: 100% !important;
}
.v-card__text {
  width: 100% !important;
}
.vue-friendly-iframe iframe {
  width: 100% !important;
  height: 62em !important;
}
</style>
