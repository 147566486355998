<template>
  <v-container fluid>
    <div class="d-flex flex-column mb-6">
      <v-select
        :items="search"
        label="Filtre de recherche des Plans de Passation des Marchés par année"
        filled
        v-model="defaultYear"
        v-on:change="onChange"
      ></v-select>
      <v-data-table
        :headers="headersPPM"
        item-key="this.$store.getters['Auth/getAllPPM'].title"
        no-data-text="Aucun Plan de passation disponible"
        :items="this.$store.getters['Auth/getListPpm']"
        @click:row="handleClick"
        class="elevation-1 px-5"
      >
        <template v-slot:[`item.publishDate`]="{ item }">{{
          item.publishDate | moment("YYYY")
        }}</template>
        <template v-slot:[`item.active`]="{ item }">
          <v-icon medium color="green darken-2" v-if="item.active">mdi-circle</v-icon>
          <v-icon medium color="grey darken-2" v-if="!item.active">mdi-circle</v-icon>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    defaultYear: new Date().getFullYear(),
    search: [new Date().getFullYear() - 1, new Date().getFullYear(), new Date().getFullYear() + 1],
    headersPPM: [
      {
        text: "Référence des Plans de Passation des Marchés publiée (Initial/révisé)",
        shortable: true,
        value: "title"
      },
      {
        text: "Nombre de Plans de Passation des Marchés révisé",
        align: "start",
        filterable: false,
        value: "revision_size"
      },
      {
        text: "Titre des Plans de Passation des Marchés",
        value: "description",
        sortable: false,
        filterable: false
      },
      { text: "Année de publication des Plans de Passation des Marchés", value: "publishDate", sortable: true }
    ],
    headers: [
      {
        text: "Status",
        align: "start",
        sortable: false,
        value: "colorState"
      },
      { text: "Type", value: "noticeType.title" },
      { text: "Objet", value: "object" },
      { text: "Autorité contractante", value: "createdBy.company.name" },
      { text: "Lieu", value: "createdBy.company.country" },
      { text: "Publié le", value: "publiDate" }
    ],
    AllNotice: []
  }),
  created() {
    const cur_year = new Date().getFullYear();
    this.$store.dispatch("Auth/loadAllPPM", cur_year);
  },
  methods: {
    onChange(e) {
      this.$store.dispatch("Auth/loadAllPPM", e);
    },
    handleClick(value) {
      const { id } = value;
      this.$router.push({ name: "show_p_ppm", params: { id } });
    },
    goto(val) {
      this.$router.push({ name: "show_p_ppm", params: { id: val } });
    }
  }
};
</script>
<style>
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  -webkit-transition: background-color 3s ease-out;
  -moz-transition: background-color 3s ease-out;
  -o-transition: background-color 3s ease-out;
  transition: background-color 3s ease-out;
  background: #eae7fd;
  text-transform: uppercase;
  cursor: pointer;
}
</style>
